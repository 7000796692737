import "./App.css";
import { useMemo } from "react";
import Countdown from 'react-countdown';
import Footer from "./components/Footer"
import Header from "./components/Header";

import "typeface-roboto"

import Home from "./Home";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

const treasury = new anchor.web3.PublicKey(
  process.env.REACT_APP_TREASURY_ADDRESS!
);

const config = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_CONFIG!
);

const candyMachineId = new anchor.web3.PublicKey(
  process.env.REACT_APP_CANDY_MACHINE_ID!
);

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
  palette: {
    type: 'dark',
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: 'flex-start',
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: '12px 16px',
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

// Renderer callback with condition
const renderer = (rendererObject: any, endpoint: any, wallets: any) => {

  if (rendererObject.completed) {
    // Render a completed state
    return (
      <ThemeProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect={true}>
            <WalletDialogProvider>
              <Home
                candyMachineId={candyMachineId}
                config={config}
                connection={connection}
                startDate={startDateSeed}
                treasury={treasury}
                txTimeout={txTimeout}
              />
            </WalletDialogProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ThemeProvider>
    )
  } else {
    // Render a countdown
    return <span style={{ fontFamily: "roboto", fontSize: "54px" }}>{rendererObject.days}:{rendererObject.hours}:{rendererObject.minutes}:{rendererObject.seconds}</span>;
  }
};

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network })
    ],
    []
  );

  return (
    <div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <Header />
      <div style={{ backgroundColor: "#252527", display: "flex", height: "100%", justifyContent: "center" }}>
        <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
          <div style={{ backgroundColor: "#252527", display: "flex", alignItems: "flex-start" }}>
            //<h1 style={{ fontFamily: "roboto", fontSize: "54px" }}>TensorBox Mint</h1>
          </div>
          <div style={{ height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Countdown date={new Date(parseInt(process.env.REACT_APP_CANDY_START_DATE!) * 1000)} renderer={(props) => renderer(props, endpoint, wallets)} />
          </div>
        </div>
      </div>
      <div >
        <Footer />
      </div>
    </div>
  );
};
export default App;
