import logo from "../assets/Parametrise_Logomark_White.png"
import "../App.css";
import Radium from 'radium';
import { DiscordIcon, TwitterIcon } from "./Icons"

let headerLinkStyle = {
    fontSize: "24px",
    color: "white",
    fontFamily: "Roboto",
    textDecoration: "none",
    ":hover": {
        color: "#5865F2"
    },
}

const Header = () => {
    return (
        <div style={{ display: "flex", backgroundColor: "#252527", height: "94.21px" }}>
            <div style={{ display: "flex", flex: "row", backgroundColor: "#252527", justifyContent: "center", width: "100%", paddingTop: "15px", paddingBottom: "15px", paddingRight: "25px", paddingLeft: "25px" }}>
                <div style={{ display: "flex", flex: "row", width: "100%", maxWidth: "1140px" }}>
                    <div>
                        <a href="https://parametrise.com/">
                            <img src={logo} style={{ display: "block", maxWidth: "100%", maxHeight: "100%", }} alt="https://parametrise.com/" />
                        </a>
                    </div>

                    <div style={{ display: "flex", flex: "row", width: "100%", paddingTop: "20px", paddingBottom: "22px", justifyContent: "flex-end", backgroundColor: "#252527" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", columnGap: "50px", fontStyle: "white", fontFamily: "roboto" }}>
                            <a style={headerLinkStyle} key="Events" href="https://parametrise.com/events">Events</a>
                            <a style={headerLinkStyle} key="Collections" href="https://parametrise.com/collections">Collections</a>
                            <a style={headerLinkStyle} key="Roadmap" href="https://parametrise.com/roadmap">Roadmap</a>
                            <a style={headerLinkStyle} key="Lite Paper" href="https://parametrise.com/lite-paper">Lite Paper</a>
                            <a style={headerLinkStyle} key="About" href="https://parametrise.com/about">About</a>
                            <TwitterIcon />
                            <DiscordIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Radium(Header);