import "../App.css";
import { DiscordIcon, TwitterIcon } from "./Icons"

const Footer = () => {
    return (
        <div style={{ display: "flex", backgroundColor: "#252527", height: "80px" }}>
            <div style={{ display: "flex", flex: "row", backgroundColor: "#252527", justifyContent: "center", width: "100%", paddingRight: "25px", paddingLeft: "25px" }}>
                <div style={{ display: "flex", flex: "row", width: "100%", maxWidth: "1140px" }}>
                    <div>
                        <h1 style={{ fontFamily: "Roboto", whiteSpace: "nowrap" }}>JOIN OUR COMMUNITY</h1>
                    </div>

                    <div style={{ display: "flex", flex: "row", width: "100%", paddingTop: "30px", paddingBottom: "12px", justifyContent: "flex-end", backgroundColor: "#252527" }}>
                        <div style={{ display: "flex", justifyContent: "space-between", columnGap: "50px", fontStyle: "white", fontFamily: "roboto" }}>
                            <TwitterIcon />
                            <DiscordIcon />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default Footer;