import { FaDiscord, FaTwitter } from 'react-icons/fa'
import { IconContext } from "react-icons";

const TwitterIcon = () => {
    return (
        <IconContext.Provider value={{ color: "white", size: "1.5em" }}>
            <a target="_blank" rel="noreferrer" href="https://twitter.com/Parametrise_NFT">
                <FaTwitter />
            </a>
        </IconContext.Provider >
    )
}

const DiscordIcon = () => {
    return (
        <IconContext.Provider value={{ color: "#7289da", size: "1.5em" }}>
            <a target="_blank" rel="noreferrer" href="https://discord.gg/kMkMKp8aCs">
                <FaDiscord />
            </a>
        </IconContext.Provider >
    )
}

export { TwitterIcon, DiscordIcon }